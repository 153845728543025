<script lang="ts" setup>
import { DateInput, RangePickerDesktopActivator } from "#components";
import { usePickerStore } from "@/stores/picker";
import { useDayjs } from "~/composables/useDayjs";
import { DatePicker } from "v-calendar";
import type { PopoverOptions } from "v-calendar/dist/types/src/utils/popovers.js";
import { useDisplay } from "vuetify";

// primry model of the component
const model = defineModel<{
  start?: string;
  end?: string;
}>({
  required: true,
});

const props = withDefaults(
  defineProps<{
    vertical?: boolean;
    minDate?: Date;
    showHours?: boolean;
    popperPlacement?: PopoverOptions["placement"];
  }>(),
  {
    showHours: true,
  }
);

const emit = defineEmits<{}>();

const { lgAndUp } = useDisplay();

const dayjs = useDayjs();

const dragValue = ref<{ start?: string; end?: string }>({
  start: undefined,
  end: undefined,
});

const timeOptions = Array.from({ length: 96 }, (_, index) => {
  const number = index * 15;
  return numberToTime(number);
});

const bottomSheet = ref(false);

const getDateInputFormat = (v?: string) => (v ? dayjs(v, "YYYYMMDDHHmm").format() : "");
const getTimeInputFormat = (v?: string) => (v ? dayjs(v, "YYYYMMDDHHmm").format("HH:mm") : "12:00");

const getModelFormat = (date?: string, time?: string) => {
  if (!date || !time) return "";
  return `${dayjs(date).format("YYYYMMDD")}${dayjs(time, "HH:mm").format("HHmm")}`;
};

// model of range picker
const pickerModel = computed({
  set: (v) => {
    model.value = {
      start: v ? getModelFormat(v.start, timeStartModel.value) : undefined,
      end: v ? getModelFormat(v.end, timeEndModel.value) : undefined,
    };
  },
  get: () => {
    return {
      start: getDateInputFormat(model.value?.start),
      end: getDateInputFormat(model.value?.end),
    };
  },
});

// time pickers
const timeStartModel = computed({
  set: (v) => {
    model.value = {
      ...model.value,
      start: getModelFormat(pickerModel.value.start, v),
    };
  },
  get: () => {
    return getTimeInputFormat(pickerModel.value.start);
  },
});

const timeEndModel = computed({
  set: (v) => {
    model.value = {
      ...model.value,
      end: getModelFormat(pickerModel.value.end, v),
    };
  },
  get: () => {
    return getTimeInputFormat(pickerModel.value.end);
  },
});

const masks = {
  input: "DD/MM/YYYY",
};

const popoverOptions = computed<Partial<PopoverOptions>>(() => ({
  visibility: "focus",
  placement: props.popperPlacement,
}));

// Manually converts dates so we can display on mobile inputs
const readOnlyMobileValue = computed(() => {
  const start = pickerModel.value.start ? dayjs(pickerModel.value.start).format("DD/MM/YYYY") : "";
  const end = pickerModel.value.end ? dayjs(pickerModel.value.end).format("DD/MM/YYYY") : "";

  return {
    start,
    end,
  };
});

const pickerRef = ref();
const isDragging = computed(() => pickerRef.value?.isDragging);
const minDate = ref(props.minDate ? props.minDate : dayjs().toDate());

// For displaying bellow the bottom-sheet
const pendingValueDisplay = computed(() => {
  const start = dragValue.value.start ? dayjs(dragValue.value.start).format("DD/MM/YYYY") : undefined;
  const end = isDragging.value
    ? undefined
    : dragValue.value.end
    ? dayjs(dragValue.value.end).format("DD/MM/YYYY")
    : undefined;

  return { start, end };
});

const handleDrag = (event: any) => (dragValue.value = event);

const getDesktopSlotProps = () => {
  return {
    timeOptions,
    timeStartModel,
    timeEndModel,
    desktopActivatorRef,
  };
};

const desktopActivatorRef = ref<InstanceType<typeof RangePickerDesktopActivator>>();

const openPicker = () => {
  // for desktop mode
  // without timeout picker flickers and closes instantly.
  // If custom trigger is used it ref should be passed to input/component ref that supports .focus method

  if (lgAndUp.value) {
    setTimeout(() => desktopActivatorRef.value?.focus(), 100);
    return;
  }

  // for mobile mode
  bottomSheet.value = true;
};

defineExpose({
  openPicker,
});

watch(bottomSheet, () => handleDrag(pickerModel.value));
</script>

<template>
  <DatePicker :columns="2" v-model.range="pickerModel" :masks="masks" :popover="popoverOptions" :minDate="minDate">
    <template #default="pickerProps">
      <slot name="desktopActivator" v-bind="{ ...getDesktopSlotProps(), pickerProps }">
        <RangePickerDesktopActivator
          :pickerProps="pickerProps"
          :timeOptions="showHours ? timeOptions : []"
          v-model:start="timeStartModel"
          v-model:end="timeEndModel"
          ref="desktopActivatorRef"
        />
      </slot>
    </template>
  </DatePicker>
  <VBottomSheet v-model="bottomSheet" height="78vh" contentClass="rounded-t-3xl overflow-hidden">
    <template #activator="{ props }">
      <slot
        name="mobileActivator"
        :props="props"
        :timeStartModel="timeStartModel"
        :timeEndModel="timeEndModel"
        :readOnlyMobileValue="readOnlyMobileValue"
        :pickerModel="pickerModel"
      >
        <RangePickerMobileActivator
          :timeOptions="showHours ? timeOptions : []"
          :activatorProps="props"
          v-model:start="timeStartModel"
          v-model:end="timeEndModel"
          :readOnlyMobileValue="readOnlyMobileValue"
        />
      </slot>
    </template>
    <div class="overflow-auto bg-white">
      <div class="relative">
        <DatePicker
          style="--vc-bg: transparent"
          v-model.range="pickerModel"
          borderless
          expanded
          :rows="10"
          title-position="left"
          :step="1"
          :minDate="minDate"
          @drag="handleDrag"
          ref="pickerRef"
        >
        </DatePicker>
      </div>
      <div class="sticky bottom-0 p-2 z-50">
        <VCard variant="elevated" class="flex px-4 py-2 shadow-2xl border border-primary">
          <div class="flex flex-1 flex-col">
            <div class="text-primary font-semibold text-sm">{{ $t("fields.arrival") }}</div>
            <div class="text-lg font-semibold">
              <span v-if="pendingValueDisplay.start">{{ pendingValueDisplay.start }}</span>
              <span v-else class="text-gray">{{ $t("buttons.select_date_short") }}</span>
            </div>
          </div>
          <div class="flex flex-1 flex-col">
            <div class="text-primary font-semibold text-sm">{{ $t("fields.departure") }}</div>

            <div class="text-lg font-semibold">
              <span v-if="pendingValueDisplay.end">{{ pendingValueDisplay.end }}</span>
              <span v-else-if="pendingValueDisplay.start" class="text-gray">{{ $t("buttons.select_date_short") }}</span>
            </div>
          </div>
        </VCard>
        <div class="flex gap-2 mt-2">
          <VBtn variant="flat" class="bg-gray-tonal text-base" size="large" @click="bottomSheet = false">
            {{ $t("buttons.close") }}
          </VBtn>
          <VBtn
            variant="elevated"
            color="primary"
            class="flex-auto text-white"
            size="large"
            @click="bottomSheet = false"
          >
            {{ $t("buttons.select_date_short", 2) }}
          </VBtn>
        </div>
      </div>
    </div>
  </VBottomSheet>
</template>
